@import "../../../partials/colors";
.ui-info-container{
    background-color: #fFF;
    max-width: 100vw;
    min-width: 100vw;
    height: 25vh;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    // border-color: color(yellow, base);
    // border-width: 2px;
    // border-style: solid;
}
.ui-info-icon{
    width: 10vw;
    height: 10vh;
    margin-bottom: 2vh;
    animation-name: iconrotate;
    animation-duration: 2s;
    animation-direction: normal;
    animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
    animation-iteration-count: infinite;
}

@keyframes iconrotate {
    0% {
        transform: rotateZ(0deg)
    }
    25% {
        transform: rotateZ(90deg)
    }
    50% {
        transform: rotateZ(0deg)
    }
    75% {
        transform: rotateZ(-90deg)
    }
    100% {
        transform: rotateZ(0deg)
    }
}

.ui-info-single {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.ui-info-content-item{
    text-align: center;
    font-size: 0.8em;
}
.ui-info-content{
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0 5vw;
}
.tittle-info{
    font-weight: bold;
    display: block;
    margin-bottom: 1vh;
    font-size: 1em !important;
}