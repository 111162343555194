@import "../../../../partials/colors";

.layout {
    background-color: rgb(248, 249, 250) !important;
    .header {
        width: 80%;
        background-color: color(salmon, base);
        .ant-menu {
            background-color: color(salmon, base);
            .ant-menu-item {
                a {
                    color: color(white, base);
                }
            }

            .ant-menu-item-active, .ant-menu-item-selected {
                a {
                    color: color(red, base);
                }
                border-bottom-color: color(red, base);
            }
        }
    }
}
.dashboard-content {
    min-width: 300px !important;
    width: 80%;
    min-height: 80vh !important;
    padding: 2vh 5vw !important;
    border-radius: 5px;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    display: flex;
    flex-direction: column;
    background-color: color(white, base);
    justify-content: flex-start;
    align-items: center;
    color: color(black, base);
    font-size: 1.5em !important;
    flex: none !important;

    .dashboard-title {
        align-self: flex-end;
        margin: 0 !important;
        margin-right: 5vw !important;
        font-size: 2em;
        font-weight: 600;
        // margin-top: -15vh !important;
    }

    .dashboard-cards-container {
        margin-top: 10vh;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-content: center;
    }

    .dashboard-card-container {
        // border: 2px color(red, base) solid;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        min-height: 20vh;
        border-radius: 5px;
        background-color: color(salmon, base);
        color: white;
    }

    .dashboard-card-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 5vh;
        // border-bottom: 1px color(red, base) solid;

        .dashboard-card-title {
            margin: 0;
            color: color(white, base);
            font-size: 1.2em;
            font-weight: 600;
        }
    }

    .dashboard-card-actions {
        width: 100%;
        display: flex;
        height: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a {
            color: color(white, base, 0.9);
            transition: all 0.5s ease-out;
            margin: 0;
            &:hover {
                text-decoration: underline
            }
            .dashboard-card-action {
                margin: 2vh 0 !important;
            }
        }
    }

}