@import url(https://fonts.googleapis.com/css?family=Biryani:700|Oswald&display=swap);
@import url(https://fonts.googleapis.com/css?family=Biryani:700|Oswald&display=swap);
html {
  min-height: 100vh; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: auto;
  min-height: 100vh;
  max-width: 100vw; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.slider-container {
  margin: 0;
  padding: 0;
  width: 100vw;
  max-width: 100vw;
  height: 92vh;
  max-height: 100vh;
  min-height: 92vh;
  margin-top: 8vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  background-position: 0px 0px; }
  .slider-container .before {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    .slider-container .before.active {
      -webkit-animation: toLeft 1000ms;
              animation: toLeft 1000ms; }

@-webkit-keyframes toLeft {
  0% {
    right: 0;
    width: 100%; }
  100% {
    right: 100%;
    width: 0%; } }

@keyframes toLeft {
  0% {
    right: 0;
    width: 100%; }
  100% {
    right: 100%;
    width: 0%; } }
    .slider-container .before.not-active {
      -webkit-animation: none;
              animation: none; }
  .slider-container .after {
    position: absolute;
    width: 0;
    height: 100%;
    left: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
    .slider-container .after.active {
      -webkit-animation: toCenter 1000ms;
              animation: toCenter 1000ms; }

@-webkit-keyframes toCenter {
  0% {
    left: 100%;
    width: 0%; }
  100% {
    left: 0;
    width: 100%; } }

@keyframes toCenter {
  0% {
    left: 100%;
    width: 0%; }
  100% {
    left: 0;
    width: 100%; } }
    .slider-container .after.not-active {
      -webkit-animation: none;
              animation: none; }

.logo-cover {
  width: 100%;
  -webkit-animation-name: logoentry;
          animation-name: logoentry;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  opacity: 0;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

@-webkit-keyframes logoentry {
  0% {
    opacity: 0;
    width: 0%; }
  100% {
    opacity: 1;
    width: 100%; } }

@keyframes logoentry {
  0% {
    opacity: 0;
    width: 0%; }
  100% {
    opacity: 1;
    width: 100%; } }

@media screen and (min-width: 576px) {
  .logo-cover {
    width: 80%; }
  @-webkit-keyframes logoentry {
    0% {
      opacity: 0;
      width: 0%; }
    100% {
      opacity: 1;
      width: 80%; } }
  @keyframes logoentry {
    0% {
      opacity: 0;
      width: 0%; }
    100% {
      opacity: 1;
      width: 80%; } } }

@media screen and (min-width: 678px) {
  .logo-cover {
    width: 70%; }
  @-webkit-keyframes logoentry {
    0% {
      opacity: 0;
      width: 7%; }
    100% {
      opacity: 1;
      width: 100%; } }
  @keyframes logoentry {
    0% {
      opacity: 0;
      width: 7%; }
    100% {
      opacity: 1;
      width: 100%; } } }

@media screen and (min-width: 992px) {
  .logo-cover {
    width: 50%;
    margin-top: 10vh; }
  @-webkit-keyframes logoentry {
    0% {
      opacity: 0;
      width: 0%; }
    100% {
      opacity: 1;
      width: 50%; } }
  @keyframes logoentry {
    0% {
      opacity: 0;
      width: 0%; }
    100% {
      opacity: 1;
      width: 50%; } } }

.countdown-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100vw;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(250, 158, 35, 0.9);
  color: #fffdf5;
  padding: 2vh 0;
  border-radius: 10px;
  z-index: 0;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  -webkit-animation-name: counterentry;
          animation-name: counterentry;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-timing-function: cubic-bezier(1, 0, 0, 1);
          animation-timing-function: cubic-bezier(1, 0, 0, 1);
  opacity: 0; }
  .countdown-container .text {
    text-align: center;
    font-weight: 700;
    font-size: 1.2em;
    line-height: 1vh;
    -webkit-animation-name: textentry;
            animation-name: textentry;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    position: relative;
    bottom: -50vh; }

@-webkit-keyframes textentry {
  0% {
    bottom: -50vh; }
  100% {
    bottom: 0; } }

@keyframes textentry {
  0% {
    bottom: -50vh; }
  100% {
    bottom: 0; } }

@-webkit-keyframes counterentry {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3) translate3d(0, 0, 0);
            transform: scale(0.3) translate3d(0, 0, 0); }
  50% {
    opacity: 0.9;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  80% {
    opacity: 1;
    -webkit-transform: scale(0.89);
            transform: scale(0.89); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0); } }

@keyframes counterentry {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3) translate3d(0, 0, 0);
            transform: scale(0.3) translate3d(0, 0, 0); }
  50% {
    opacity: 0.9;
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  80% {
    opacity: 1;
    -webkit-transform: scale(0.89);
            transform: scale(0.89); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1) translate3d(0, 0, 0);
            transform: scale(1) translate3d(0, 0, 0); } }
  .countdown-container .countdown {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; }
    .countdown-container .countdown .time-containers {
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      -webkit-animation-name: countdownentry;
              animation-name: countdownentry;
      -webkit-animation-duration: 1s;
              animation-duration: 1s;
      -webkit-animation-delay: 2.5s;
              animation-delay: 2.5s;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
      margin: 0 5px; }

@-webkit-keyframes countdownentry {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes countdownentry {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
      .countdown-container .countdown .time-containers .single-container {
        display: flex;
        flex-direction: row;
        align-items: center; }
        .countdown-container .countdown .time-containers .single-container .single-number {
          background-color: #fffdf5;
          padding: 5px;
          margin: 0 2px;
          color: #fa9e23;
          font-size: 2.5em;
          display: flex;
          justify-content: center;
          align-items: center; }
      .countdown-container .countdown .time-containers p {
        width: 100%;
        margin: 0;
        margin-top: 5px;
        margin-left: 10px;
        text-align: left;
        font-size: 0.8em;
        font-weight: 700; }

@media screen and (min-width: 576px) {
  .countdown-container {
    bottom: 100px;
    width: 80%; }
    .countdown-container .text {
      font-size: 1.5em;
      font-weight: 700; }
  .single-number {
    font-size: 3em !important;
    font-weight: 600; } }

@media screen and (min-width: 678px) {
  .countdown-container {
    width: 70%; }
    .countdown-container .text {
      font-size: 1.5em !important; }
  .single-number {
    font-size: 3em !important; } }

@media screen and (min-width: 992px) {
  .countdown-container {
    width: 60vw;
    margin-top: 1vh;
    padding-top: 3vh; }
    .countdown-container .text {
      font-size: 1.8em !important;
      line-height: 10px; }
  .time-containers {
    margin: 0 1vw !important;
    padding-top: 2vh !important; }
    .time-containers p {
      font-size: 1em !important; }
  .single-number {
    font-size: 3.5em !important;
    font-weight: 600; } }

@media screen and (min-width: 1200px) {
  .countdown-container {
    width: 30%;
    padding-top: 2vh !important; }
  .time-containers {
    padding-bottom: 0vh !important; }
  .single-number {
    font-size: 2rem !important;
    font-weight: 600; } }

.styles_SpeakerCard__1_0m8 {
  width: 75px;
  height: 75px;
  overflow: hidden;
  background-color: #fffdf5;
  padding: 5px 25px 5px 45px;
  margin: 25px 0;
  border-radius: 0px 25px 25px 0;
  position: relative;
  box-shadow: 10px 10px 0px rgba(255, 253, 245, 0.3);
  -webkit-transform: translateX(-15px);
          transform: translateX(-15px);
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
  display: flex;
  flex-direction: column; }
  .styles_SpeakerCard__1_0m8.styles_focus__29EB9 {
    width: 275px; }
    .styles_SpeakerCard__1_0m8.styles_focus__29EB9 .styles_name__1KfTm {
      visibility: visible;
      opacity: 1; }
  .styles_SpeakerCard__1_0m8.styles_opened__2rSn6 {
    width: 100vw;
    height: 275px;
    box-sizing: border-box; }
    .styles_SpeakerCard__1_0m8.styles_opened__2rSn6 .styles_focusArea__2SQX4 {
      height: 75px;
      grid-template-columns: calc((100% / 12) * 4) calc((100% / 12) * 8); }
    .styles_SpeakerCard__1_0m8.styles_opened__2rSn6 .styles_name__1KfTm, .styles_SpeakerCard__1_0m8.styles_opened__2rSn6 .styles_bio__2M0u6 {
      visibility: visible;
      opacity: 1; }
  .styles_SpeakerCard__1_0m8 .styles_focusArea__2SQX4 {
    height: 75px;
    display: grid;
    grid-template-columns: calc((275px / 12) * 4) calc((275px / 12) * 8);
    justify-items: center;
    align-items: center;
    -webkit-transition: .4s ease-in;
    transition: .4s ease-in; }
    .styles_SpeakerCard__1_0m8 .styles_focusArea__2SQX4 .styles_imgCont__1abXo {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      overflow: hidden;
      position: relative; }
      .styles_SpeakerCard__1_0m8 .styles_focusArea__2SQX4 .styles_imgCont__1abXo img {
        position: absolute;
        height: 100%;
        display: block; }
  .styles_SpeakerCard__1_0m8 .styles_name__1KfTm, .styles_SpeakerCard__1_0m8 .styles_bio__2M0u6 {
    visibility: hidden;
    font-weight: 600;
    font-size: 1.2rem;
    color: #de576a;
    opacity: 0;
    -webkit-transition: .4s ease-in;
    transition: .4s ease-in; }
  .styles_SpeakerCard__1_0m8 .styles_bio__2M0u6 {
    display: flex;
    flex: 1 1 auto;
    align-items: center; }

.ui-about-container {
  max-width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  background-image: url(/static/media/skyline.5226749b.png);
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  text-align: center; }

.ui-about-content-text {
  width: 75vw;
  height: auto;
  background: #fa9e23;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15vh;
  margin-bottom: 8vh;
  padding-bottom: 5vh;
  border: 1px;
  border-radius: 150px 0px 150px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: -28px 28px 0px -4px rgba(255, 255, 255, 0.26); }

.ui-about-text {
  width: 60vw; }

.ui-about-text-tittle {
  color: #fff;
  text-transform: uppercase;
  font-size: 4vh;
  font-weight: bold;
  display: block;
  text-align: center;
  margin: 3vh 0;
  font-family: "Biryani", sans-serif; }

.ui-about-text-content {
  color: #fff;
  display: block;
  text-align: justify;
  font-size: 24px;
  line-height: 30px;
  font-family: "Oswald", sans-serif; }

@media screen and (max-width: 768px) {
  .ui-about-container {
    width: 100vw;
    min-width: 100vw; }
  .ui-img {
    display: block;
    width: 100vw;
    height: 22vh;
    background-color: black; }
  .ui-about-content-text {
    width: 70vw;
    height: auto;
    background: #fa9e23;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vh;
    margin-bottom: 8vh;
    border: 1px;
    border-radius: 80px 0px 80px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -20px 20px 0px -4px rgba(255, 255, 255, 0.26); }
  .ui-about-text {
    width: 60vw; }
  .ui-about-text-tittle {
    color: #fff;
    text-transform: uppercase;
    font-size: 4vh;
    display: block;
    text-align: center;
    margin-bottom: 2vh; }
  .ui-about-text-content {
    color: #fff;
    display: block;
    text-align: center;
    font-size: 15px; } }

@media screen and (min-width: 400px) {
  .ui-about-text-content {
    font-size: 18px; } }

@media screen and (max-width: 375px) {
  .ui-about-text-content {
    font-size: 18px;
    line-height: 23px; } }

@media screen and (max-width: 320px) {
  .ui-about-text-content {
    font-size: 15px;
    line-height: 23px; } }

@media screen and (min-width: 420px) {
  .ui-about-text-content {
    font-size: 18px;
    line-height: 20px; } }

@media screen and (min-width: 768px) {
  .ui-about-text-content {
    font-size: 24px;
    line-height: 30px; } }

.Navbar {
  width: 100%;
  overflow-x: hidden;
  z-index: 10000; }
  .Navbar.right-move + div {
    position: fixed;
    left: 70vw;
    top: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    overflow-x: hidden; }
  .Navbar.right-move header {
    left: 70vw; }
  .Navbar.left-move + div {
    left: 0vw;
    -webkit-animation: back-position 0.3s;
            animation: back-position 0.3s; }

@-webkit-keyframes back-position {
  from {
    position: fixed;
    left: 70vw;
    top: 0; }
  to {
    position: fixed;
    left: 0vw;
    top: 0; } }

@keyframes back-position {
  from {
    position: fixed;
    left: 70vw;
    top: 0; }
  to {
    position: fixed;
    left: 0vw;
    top: 0; } }
  .Navbar.left-move header {
    left: 0vw; }
  .Navbar header {
    z-index: 1000;
    -webkit-transition: 0.3s;
    transition: 0.3s; }
    .Navbar header .mobile-version {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 8vh;
      z-index: 1000;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
      background-color: #fff;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0; }
      .Navbar header .mobile-version img:nth-child(2) {
        height: 10vh; }
      .Navbar header .mobile-version img:nth-child(1), .Navbar header .mobile-version img:nth-child(3) {
        height: 5vh; }
    .Navbar header .desktop-version {
      display: none; }
  .Navbar .side-bar {
    z-index: 100;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    width: 70vw;
    height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    background: #fff;
    position: fixed; }
    .Navbar .side-bar.side-visible {
      left: 0%;
      top: 0%; }
    .Navbar .side-bar.side-hidde {
      left: -70vw;
      top: 0%; }
    .Navbar .side-bar .cross {
      width: 3vh;
      margin: 20px; }
    .Navbar .side-bar ul {
      width: 100%;
      height: 80vh;
      padding: 0;
      margin: 0;
      list-style: none; }
      .Navbar .side-bar ul .link {
        color: #ba484a;
        font-size: 1.5em;
        margin: 5vh 30px;
        -webkit-transition: 0.3s;
        transition: 0.3s; }
        .Navbar .side-bar ul .link a {
          color: #ba484a; }
        .Navbar .side-bar ul .link:active {
          -webkit-transform: translateX(15px);
                  transform: translateX(15px);
          opacity: 0.7; }
    .Navbar .side-bar .logo-sidebar {
      margin: auto;
      width: 20vw; }
  .Navbar .black-div {
    position: fixed;
    left: 0;
    top: 8vh;
    height: 92vh;
    width: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    z-index: 100; }
    .Navbar .black-div.visible-div {
      width: 100vw;
      -webkit-animation: blur-animate 0.3s;
              animation: blur-animate 0.3s;
      left: 70vw;
      background-color: #0009; }

@-webkit-keyframes blur-animate {
  from {
    background-color: #0000; }
  to {
    background-color: #0009; } }

@keyframes blur-animate {
  from {
    background-color: #0000; }
  to {
    background-color: #0009; } }
    .Navbar .black-div.hidden-div {
      left: 0;
      -webkit-animation: ex-blur-animate 0.3s;
              animation: ex-blur-animate 0.3s; }

@-webkit-keyframes ex-blur-animate {
  from {
    background-color: #0009;
    width: 100vw; }
  to {
    background-color: #0000;
    width: 100vw; } }

@keyframes ex-blur-animate {
  from {
    background-color: #0009;
    width: 100vw; }
  to {
    background-color: #0000;
    width: 100vw; } }

@media screen and (min-width: 992px) {
  .mobile-version {
    display: none !important; }
  .side-bar {
    display: none !important; }
  .desktop-version {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    height: 8vh;
    z-index: 100;
    background-color: #fffdf5;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3); }
    .desktop-version img {
      height: 15vh; }
    .desktop-version ul {
      width: 75vw;
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      height: 8vh;
      margin: 0;
      padding: 0;
      list-style: none;
      font-weight: 600; }
      .desktop-version ul li {
        font-size: 1em; }
        .desktop-version ul li a {
          color: rgba(0, 0, 0, 0.65); }
  @-webkit-keyframes side-down {
    0% {
      position: fixed;
      top: -15vh; }
    100% {
      position: fixed;
      top: 0vh; } }
  @keyframes side-down {
    0% {
      position: fixed;
      top: -15vh; }
    100% {
      position: fixed;
      top: 0vh; } }
  @-webkit-keyframes side-up {
    0% {
      position: fixed;
      top: 0vh; }
    100% {
      position: fixed;
      top: -15vh; } }
  @keyframes side-up {
    0% {
      position: fixed;
      top: 0vh; }
    100% {
      position: fixed;
      top: -15vh; } } }

.ui-info-container {
  background-color: #fFF;
  max-width: 100vw;
  min-width: 100vw;
  height: 25vh;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }

.ui-info-icon {
  width: 10vw;
  height: 10vh;
  margin-bottom: 2vh;
  -webkit-animation-name: iconrotate;
          animation-name: iconrotate;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
          animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; }

@-webkit-keyframes iconrotate {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  25% {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }
  50% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  75% {
    -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg); }
  100% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); } }

@keyframes iconrotate {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  25% {
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }
  50% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); }
  75% {
    -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg); }
  100% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg); } }

.ui-info-single {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center; }

.ui-info-content-item {
  text-align: center;
  font-size: 0.8em; }

.ui-info-content {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0 5vw; }

.tittle-info {
  font-weight: bold;
  display: block;
  margin-bottom: 1vh;
  font-size: 1em !important; }

.sponsors-container {
  width: 100vw;
  text-align: center; }
  .sponsors-container .sponsors-title {
    font-size: 2em;
    font-weight: 600;
    position: relative;
    color: #b13b3c;
    -webkit-animation-name: sponsorstitle;
            animation-name: sponsorstitle;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
            animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

@-webkit-keyframes sponsorstitle {
  0% {
    top: -1000px;
    left: -1000px; }
  100% {
    top: 0;
    left: 0; } }

@keyframes sponsorstitle {
  0% {
    top: -1000px;
    left: -1000px; }
  100% {
    top: 0;
    left: 0; } }
  .sponsors-container .sponsor-single {
    width: 40vw;
    display: inline-block;
    margin: 4vw;
    background-color: #FFFDF5;
    border-radius: 15px;
    box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.3);
    -webkit-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out; }
    .sponsors-container .sponsor-single:hover .sponsor-title {
      opacity: 0; }
    .sponsors-container .sponsor-single:hover .social-media-container {
      opacity: 1; }
    .sponsors-container .sponsor-single:hover .sponsor-body {
      height: 10vh; }
    .sponsors-container .sponsor-single .sponsor-image {
      width: 100%; }
      .sponsors-container .sponsor-single .sponsor-image img {
        width: 100%;
        border-radius: 15px 15px 0 0; }
    .sponsors-container .sponsor-single .sponsor-body {
      height: 5vh;
      -webkit-transition: height 0.5s ease-in;
      transition: height 0.5s ease-in; }
      .sponsors-container .sponsor-single .sponsor-body .sponsor-title {
        padding: 1vh;
        margin: 0;
        font-size: 1.2em;
        font-weight: 600;
        -webkit-transition: opacity 0.5s ease-out;
        transition: opacity 0.5s ease-out; }
    .sponsors-container .sponsor-single .social-media-container {
      width: 100%;
      display: flex;
      margin-top: -5vh;
      height: 10vh;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      opacity: 0;
      -webkit-transition: opacity 0.5s ease-in;
      transition: opacity 0.5s ease-in; }
      .sponsors-container .sponsor-single .social-media-container .sponsor-icon {
        width: 30px;
        height: 30px; }

@media screen and (min-width: 576px) {
  .sponsor-single {
    width: 35vw !important;
    margin: 10vw; } }

@media screen and (min-width: 678px) {
  .sponsor-single {
    width: 25vw !important;
    margin: 10vw; } }

@media screen and (min-width: 992px) {
  .sponsor-single {
    width: 20vw !important;
    margin: 15vw; } }

.layout {
  min-height: 100vh !important;
  margin-top: 8vh;
  width: 100%;
  background-color: #fffdf5 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important; }
  .layout .content {
    min-width: 300px !important;
    padding: 5vh 0 !important;
    margin: 5vh 0 !important;
    max-width: 80%;
    background-color: #f26d64;
    border-radius: 5px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #fffdf5;
    font-size: 1.5em !important;
    flex: none; }
    .layout .content .login-title {
      font-size: 1.5em; }
    .layout .content input {
      width: 80%;
      min-height: 40px;
      height: 4vh;
      margin: 10px 0;
      text-align: center; }
      .layout .content input::-webkit-input-placeholder {
        text-align: center;
        color: rgba(0, 0, 0, 0.4); }
      .layout .content input::-moz-placeholder {
        text-align: center;
        color: rgba(0, 0, 0, 0.4); }
      .layout .content input:-ms-input-placeholder {
        text-align: center;
        color: rgba(0, 0, 0, 0.4); }
      .layout .content input::-ms-input-placeholder {
        text-align: center;
        color: rgba(0, 0, 0, 0.4); }
      .layout .content input::placeholder {
        text-align: center;
        color: rgba(0, 0, 0, 0.4); }
      .layout .content input:focus, .layout .content input:active, .layout .content input:hover {
        border-color: rgba(250, 158, 35, 0.8);
        border-width: 2px; }
    .layout .content .ant-input-password {
      width: 80% !important; }
      .layout .content .ant-input-password input {
        width: 100% !important; }
    .layout .content .login-btn {
      border-radius: 20px;
      margin: 10px 0;
      color: #b13b3c;
      font-size: 1em;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      cursor: pointer;
      border: 0;
      outline: none; }
      .layout .content .login-btn:hover {
        -webkit-transform: translateY(-7px);
                transform: translateY(-7px); }

.layout {
  margin-top: 8vh;
  width: 100%;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important; }

.content {
  min-width: 300px !important; }
  .content input {
    width: 80%;
    min-height: 40px;
    height: 4vh;
    margin: 10px 0;
    text-align: center; }
    .content input::-webkit-input-placeholder {
      text-align: center;
      color: rgba(0, 0, 0, 0.4); }
    .content input::-moz-placeholder {
      text-align: center;
      color: rgba(0, 0, 0, 0.4); }
    .content input:-ms-input-placeholder {
      text-align: center;
      color: rgba(0, 0, 0, 0.4); }
    .content input::-ms-input-placeholder {
      text-align: center;
      color: rgba(0, 0, 0, 0.4); }
    .content input::placeholder {
      text-align: center;
      color: rgba(0, 0, 0, 0.4); }
    .content input:focus, .content input:active, .content input:hover {
      border-color: rgba(250, 158, 35, 0.8);
      border-width: 2px; }
  .content .ant-dropdown-trigger {
    width: 80%;
    min-height: 40px;
    height: 4vh;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .content .ant-dropdown-trigger span {
      width: 100%; }
    .content .ant-dropdown-trigger i {
      margin: 0 0 0 -12px !important; }
  .content .register-btn {
    border-radius: 20px;
    margin: 1vh 0;
    color: #f26d64;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    border: 0;
    outline: none; }
    .content .register-btn:hover {
      color: #fa9e23 !important;
      -webkit-transform: translateY(-7px);
              transform: translateY(-7px); }

.contact-form-container {
  border-radius: 20px;
  padding: 5vh 1vh;
  margin: 10vh auto;
  background-color: #b13b3c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.3); }
  .contact-form-container .contact-form-instructions {
    width: 100%;
    color: #fffdf5;
    font-size: 1.5em;
    font-weight: 600;
    text-align: center; }
  .contact-form-container .contact-form-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; }
    .contact-form-container .contact-form-form input, .contact-form-container .contact-form-form textarea {
      width: 80%;
      margin: 2vh 0; }
      .contact-form-container .contact-form-form input::-webkit-input-placeholder, .contact-form-container .contact-form-form textarea::-webkit-input-placeholder {
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
        font-size: 1em;
        font-weight: 600; }
      .contact-form-container .contact-form-form input::-moz-placeholder, .contact-form-container .contact-form-form textarea::-moz-placeholder {
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
        font-size: 1em;
        font-weight: 600; }
      .contact-form-container .contact-form-form input:-ms-input-placeholder, .contact-form-container .contact-form-form textarea:-ms-input-placeholder {
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
        font-size: 1em;
        font-weight: 600; }
      .contact-form-container .contact-form-form input::-ms-input-placeholder, .contact-form-container .contact-form-form textarea::-ms-input-placeholder {
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
        font-size: 1em;
        font-weight: 600; }
      .contact-form-container .contact-form-form input::placeholder, .contact-form-container .contact-form-form textarea::placeholder {
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
        font-size: 1em;
        font-weight: 600; }
      .contact-form-container .contact-form-form input:focus, .contact-form-container .contact-form-form input:active, .contact-form-container .contact-form-form input:hover, .contact-form-container .contact-form-form textarea:focus, .contact-form-container .contact-form-form textarea:active, .contact-form-container .contact-form-form textarea:hover {
        border-color: rgba(250, 158, 35, 0.8);
        border-width: 2px; }
    .contact-form-container .contact-form-form input {
      height: 5vh; }
    .contact-form-container .contact-form-form button {
      border-radius: 20px;
      margin: 10px 0;
      color: #b13b3c;
      font-size: 1em;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      cursor: pointer;
      border: 0;
      outline: none; }
      .contact-form-container .contact-form-form button:hover {
        -webkit-transform: translateY(-7px);
                transform: translateY(-7px); }

@media screen and (min-width: 678px) {
  .contact-form-container {
    width: 70%; } }

@media screen and (min-width: 992px) {
  .contact-form-container {
    width: 60%; } }

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box; }

.styles_TeamOrganizer__1zTXP {
  margin-bottom: 100px; }

.styles_centerContent__1RzMX {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center; }

.styles_header__3PWfW {
  max-height: 40vh;
  overflow: hidden;
  display: flex;
  align-items: center; }
  .styles_header__3PWfW img {
    width: 100%; }

.styles_TeamContainer__1OESa {
  padding: 20px 30px !important;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center; }
  .styles_TeamContainer__1OESa .styles_titleContainer__27pnn {
    width: 300px;
    height: 58px;
    background-color: #fffdf5;
    border-radius: 50px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    margin: 0px; }
    .styles_TeamContainer__1OESa .styles_titleContainer__27pnn h3 {
      width: 100%;
      margin-top: 15px;
      text-align: center;
      font-weight: 600;
      font-size: 1.3rem;
      color: #b13b3c; }

[class*="card"] {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center; }

.styles_OrganizerCard__1-U01 {
  width: 100%;
  height: 250px;
  padding: 10px;
  margin-left: 30px;
  background-color: #fffdf5 !important;
  border-radius: 0px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(-15px);
          transform: translateX(-15px);
  -webkit-transition: .4s ease-in-out;
  transition: .4s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-around;
  align-items: center;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  .styles_OrganizerCard__1-U01:hover .styles_cardImgCont__10ELX img {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); }
  .styles_OrganizerCard__1-U01.styles_firstCorner__3Exs- {
    border-radius: 25px 0px 0px 0px !important; }
  .styles_OrganizerCard__1-U01.styles_secondCorner__1IMu6 {
    border-radius: 0px 25px 0px 0px !important; }
  .styles_OrganizerCard__1-U01.styles_thirdCroner__1iL3z {
    border-radius: 0px 0px 25px 0px !important; }
  .styles_OrganizerCard__1-U01.styles_fourthCorner__12JFn {
    border-radius: 0px 0px 0px 25px !important; }
  .styles_OrganizerCard__1-U01 .styles_cardImgCont__10ELX {
    width: 100%;
    height: 100px;
    border-radius: 50%;
    position: relative; }
    .styles_OrganizerCard__1-U01 .styles_cardImgCont__10ELX img {
      height: 100%;
      border-radius: 100%; }
  .styles_OrganizerCard__1-U01 .styles_cardName__24VLK {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 0.9rem;
    color: black; }
  .styles_OrganizerCard__1-U01 .styles_cardRole__24gUA {
    max-width: 100%;
    margin: 0px;
    font-weight: 600;
    text-align: center;
    font-size: 0.9rem;
    color: #b13b3c; }
  .styles_OrganizerCard__1-U01 .styles_instagram__1aDbd {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .styles_OrganizerCard__1-U01 .styles_instagramIcon__3NS-O {
    width: 30px;
    height: 30px;
    margin-left: 10px; }
  .styles_OrganizerCard__1-U01 .styles_cardBio__3HLrV {
    width: 100%;
    margin: 0px;
    font-weight: 600;
    font-size: 0.9rem;
    color: #fa9e23; }

[class*="organizerCont"] {
  position: relative;
  margin: auto;
  width: 100%;
  height: auto; }

.styles_Row__3UoIA {
  position: relative;
  margin: auto;
  width: 100%;
  height: auto; }

[class*="col"] {
  float: left;
  padding: 10px; }

@media (min-width: 1200px) {
  .styles_colL3__3ipWi {
    width: 33.33333333%;
    padding: 15px 15px; }
  .styles_organizerContL__3MxZg {
    padding: 0px 300px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .styles_colM3__r_aSv {
    width: 50%;
    padding: 15px 15px; }
  .styles_organizerContM__3YMf6 {
    padding: 0px 250px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .styles_colS4__3MjtY {
    width: 50%;
    padding: 15px 30px; }
  .styles_organizerContS__3oFxD {
    padding: 0px 145px; } }

@media (max-width: 767px) {
  .styles_colXS6__2lvZi {
    width: 50%; }
  .styles_organizerContS__3oFxD {
    padding: 0px 0px; } }

.layout {
  background-color: #f8f9fa !important; }
  .layout .header {
    width: 80%;
    background-color: #f26d64; }
    .layout .header .ant-menu {
      background-color: #f26d64; }
      .layout .header .ant-menu .ant-menu-item a {
        color: #fffdf5; }
      .layout .header .ant-menu .ant-menu-item-active, .layout .header .ant-menu .ant-menu-item-selected {
        border-bottom-color: #b13b3c; }
        .layout .header .ant-menu .ant-menu-item-active a, .layout .header .ant-menu .ant-menu-item-selected a {
          color: #b13b3c; }

.dashboard-content {
  min-width: 300px !important;
  width: 80%;
  min-height: 80vh !important;
  padding: 2vh 5vw !important;
  border-radius: 5px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;
  background-color: #fffdf5;
  justify-content: flex-start;
  align-items: center;
  color: black;
  font-size: 1.5em !important;
  flex: none !important; }
  .dashboard-content .dashboard-title {
    align-self: flex-end;
    margin: 0 !important;
    margin-right: 5vw !important;
    font-size: 2em;
    font-weight: 600; }
  .dashboard-content .dashboard-cards-container {
    margin-top: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center; }
  .dashboard-content .dashboard-card-container {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    min-height: 20vh;
    border-radius: 5px;
    background-color: #f26d64;
    color: white; }
  .dashboard-content .dashboard-card-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 5vh; }
    .dashboard-content .dashboard-card-header .dashboard-card-title {
      margin: 0;
      color: #fffdf5;
      font-size: 1.2em;
      font-weight: 600; }
  .dashboard-content .dashboard-card-actions {
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .dashboard-content .dashboard-card-actions a {
      color: rgba(255, 253, 245, 0.9);
      -webkit-transition: all 0.5s ease-out;
      transition: all 0.5s ease-out;
      margin: 0; }
      .dashboard-content .dashboard-card-actions a:hover {
        text-decoration: underline; }
      .dashboard-content .dashboard-card-actions a .dashboard-card-action {
        margin: 2vh 0 !important; }

.ant-table-column-has-actions {
  width: 33% !important; }

tr:hover td, tr:active td, tr:focus td {
  background-color: rgba(250, 158, 35, 0.2) !important; }

.ant-table table, th {
  text-align: center !important; }

.ant-table-thead, th {
  color: #fa9e23 !important;
  font-size: 1.2em; }

.anticon-search {
  color: #fa9e23 !important; }

.list-title {
  margin: 0 !important;
  font-size: 2em;
  color: #f26d64;
  text-align: center; }

.ant-table-filter-dropdown .ant-input:hover, .ant-table-filter-dropdown .ant-input:active, .ant-table-filter-dropdown .ant-input:focus {
  border-color: #f26d64 !important;
  box-shadow: 0 0 0 2px rgba(250, 158, 35, 0.5) !important; }

.ant-table-filter-dropdown .ant-btn-primary {
  background-color: #f26d64 !important;
  border: none; }
  .ant-table-filter-dropdown .ant-btn-primary:hover {
    color: #fffdf5 !important; }

.ant-table-filter-dropdown button:hover, .ant-table-filter-dropdown button:active, .ant-table-filter-dropdown button:focus {
  border-color: #f26d64 !important;
  color: #f26d64 !important; }

.ant-pagination-item-active,
.ant-pagination-item:hover {
  border-color: #f26d64 !important; }
  .ant-pagination-item-active a,
  .ant-pagination-item:hover a {
    color: #f26d64 !important; }

.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #f26d64 !important; }

.ant-pagination-prev:hover .anticon-left, .ant-pagination-prev:hover .anticon-right, .ant-pagination-next:hover .anticon-left, .ant-pagination-next:hover .anticon-right {
  color: #f26d64 !important; }


