@import "../../../../partials/colors";
@import "../../../../partials/breakpoints";

.countdown-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100vw;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: color(yellow, base, 0.9);
    color: color(white, base);
    padding: 2vh 0;
    border-radius: 10px;
    z-index: 0;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.3);
    .text {
        text-align: center;
        font-weight: 700;
        font-size: 1.2em;
        line-height: 1vh;
        animation-name: textentry;
        animation-duration: 1.5s;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        position: relative;
        bottom: -50vh;

        @keyframes textentry {
            0% {
                bottom: -50vh;
            }
            100% {
                bottom: 0;
            }
        }
    }
    animation-name: counterentry;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
    animation-timing-function: cubic-bezier(1, 0, 0, 1);
    // position: relative;
    opacity: 0;

    @keyframes counterentry {
        0%{
            opacity: 0;
            transform: scale(0.3) translate3d(0,0,0);
          }
          50%{
            opacity: 0.9;
            transform: scale(1.1);
          }
          80%{
            opacity: 1;
            transform: scale(0.89);
          }
          100%{
            opacity: 1;
            transform: scale(1) translate3d(0,0,0);
          }
    }

    .countdown {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        .time-containers {
            display: flex;
            flex-direction: column;
            align-items: center;
            opacity: 0;
            animation-name: countdownentry;
            animation-duration: 1s;
            animation-delay: 2.5s;
            animation-timing-function: linear;
            animation-fill-mode: forwards;

            @keyframes countdownentry {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

            .single-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                .single-number {
                    background-color: color(white, base);
                    padding: 5px;
                    margin: 0 2px;
                    color: color(yellow, base);
                    // height: 7vh;
                    font-size: 2.5em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            margin: 0 5px;
            p {
                width: 100%;
                margin: 0;
                margin-top: 5px;
                margin-left: 10px;
                text-align: left;
                font-size: 0.8em;
                font-weight: 700;
            }
        }
    }
}

@include for-phone {
    .countdown-container {
        bottom: 100px;
        width: 80%;
        .text {
            font-size: 1.5em;
            font-weight: 700;
        }
    }

    .single-number {
        font-size: 3em !important;
        font-weight: 600;
    }
}

@include for-tablet {
    .countdown-container {
        width: 70%;
        .text {
            font-size: 1.5em !important;
        }
    }

    .single-number {
        font-size: 3em !important;
    }
}

@include for-desktop {
    .countdown-container {
        width: 60vw;
        margin-top: 1vh;
        padding-top: 3vh;
        .text {
            font-size: 1.8em !important;
            line-height: 10px;
        }
    }

    .time-containers {
        margin: 0 1vw !important;
        padding-top: 2vh !important;
        p {
            font-size: 1em !important;
        }
    }

    .single-number {
        font-size: 3.5em !important;
        font-weight: 600;
    }
}

@include for-large {
    .countdown-container {
        width: 30%;
        padding-top: 2vh !important;
    }
    .time-containers {
        padding-bottom: 0vh !important;
    }
    .single-number {
        font-size: 2rem !important;
        font-weight: 600;
    }
}
