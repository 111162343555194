$pink: #F48E9C;

$yellow: #FA9E23;

$red: #B13B3C;

$white: #FFFDF5;

$black: #000;

$brown: #DC783D;

$salmon: #F26D64;

$gold: #E48F1F;

$skin: #EEAA87;

$palette: (
  pink: (
    base: $pink,
    light: lighten($pink, 15%),
    dark: desaturate(darken($pink, 15%), 15%),
  ),
  red: (
    base: $red,
    light: lighten($red, 15%),
    dark: darken($red, 15%),
  ),
  yellow: (
    base: $yellow,
    light: lighten($yellow, 15%),
    dark: darken($yellow, 15%),
  ),
  black: (
    base: $black,
    light: lighten($black, 15%),
  ),
  white: (
    base: $white,
    dark: darken($white, 15%),
  ),
  salmon: (
    base: $salmon,
    ligth: lighten($salmon, 15),
    dark: darken($salmon, 15%),
  ),
  brown: (
    base: $brown,
    ligth: lighten($brown, 15),
    dark: darken($brown, 15%),
  ),
  gold: (
    base: $gold,
    ligth: lighten($gold, 15),
    dark: darken($gold, 15%),
  ),
  skin: (
    base: $skin,
    ligth: lighten($skin, 15),
    dark: darken($skin, 15%),
  )
);

@function color($color, $tone, $alpha: 1){

  // @warn map-get($palette,$color);

  @if map-has-key($palette,$color){
    $color: map-get($palette,$color);

      @if map-has-key($color,$tone){
        $tone: map-get($color,$tone);
        @return rgba($tone, $alpha);
      }

      @warn "unknown tone `#{$tone}` in color";
      @return null;
  }

  @warn "unknown color `#{$color}` in palette";
  @return null;

}