$phone: 576px;
$tablet: 678px;
$desktop: 992px;
$large: 1200px;

@mixin for-phone {
    @media screen and (min-width: $phone) {
        @content;
    }
}

@mixin for-tablet {
    @media screen and (min-width: $tablet) {
        @content;
    }
}

@mixin for-desktop {
    @media screen and (min-width: $desktop) {
        @content;
    }
}

@mixin for-large {
    @media screen and (min-width: $large) {
        @content;
    }
}