@import './partials/colors';
@import url('https://fonts.googleapis.com/css?family=Biryani:700|Oswald&display=swap');

html {
  min-height: 100vh;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
//   background-image: linear-gradient(color(white, base), color(pink, base));
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: auto;
  min-height: 100vh;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
