@import "../../../../partials/colors";

.layout {
    min-height: 100vh !important;
    margin-top: 8vh;
    width: 100%;
    // background-image: url('../../../../images/tenango-background.png') !important;
    // background-size: cover;
    background-color: color(white, base) !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;

    .content {
        min-width: 300px !important;
        padding: 5vh 0 !important;
        margin: 5vh 0 !important;
        max-width: 80%;
        background-color: color(salmon, base);
        border-radius: 5px;
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        color: color(white, base);
        font-size: 1.5em !important;
        flex: none;

        .login-title {
            font-size: 1.5em;
        }

        input {
            width: 80%;
            min-height: 40px;
            height: 4vh;
            margin: 10px 0;
            text-align: center;
            &::placeholder {
                text-align: center;
                color: color(black, base, 0.4);
            }

            &:focus, &:active, &:hover {
                border-color: color(yellow, base, 0.8);

                border-width: 2px;
            }
        }
        .ant-input-password {
            width: 80% !important;
            input {
                width: 100% !important;
            }
        }

        .login-btn {
            border-radius: 20px;
            margin: 10px 0;
            color: color(red, base);
            font-size: 1em;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 2.5px;
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease 0s;
            cursor: pointer;
            border: 0;
            outline: none;

            &:hover {
                // background-color: color(red, base);
                // box-shadow: 0px 15px 20px color(yellow, base, 0.4);
                // color: #fff;
                transform: translateY(-7px);
            }
        }
    }
}

