@import "../../../../partials/colors";
.layout {
    margin-top: 8vh;
    width: 100%;
    // background-image: url('../../../../images/tenango-background.png') !important;
    // background-size: cover;
    // background-color: color(white, base) !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}
.content {
    min-width: 300px !important;
    input {
        width: 80%;
        min-height: 40px;
        height: 4vh;
        margin: 10px 0;
        text-align: center;
        &::placeholder {
            text-align: center;
            color: color(black, base, 0.4);
        }

        &:focus, &:active, &:hover {
            border-color: color(yellow, base, 0.8);

            border-width: 2px;
        }
    }
    .ant-dropdown-trigger {
        width: 80%;
        min-height: 40px;
        height: 4vh;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            width: 100%;
        }
        i {
            margin:0 0 0 -12px !important;
        }
    }
    .register-btn {
        border-radius: 20px;
        margin: 1vh 0;
        color: color(salmon, base);
        font-size: 1em;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease 0s;
        cursor: pointer;
        border: 0;
        outline: none;

        &:hover {
            // background-color: color(red, base);
            // box-shadow: 0px 15px 20px color(yellow, base, 0.4);
            // color: #fff;
            color: color(yellow,  base) !important;
            transform: translateY(-7px);
        }
    }
}