* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box; }

.TeamOrganizer {
  margin-bottom: 100px; }

.centerContent {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center; }

.header {
  max-height: 40vh;
  overflow: hidden;
  display: flex;
  align-items: center; }
  .header img {
    width: 100%; }

.TeamContainer {
  padding: 20px 30px !important;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center; }
  .TeamContainer .titleContainer {
    width: 300px;
    height: 58px;
    background-color: #fffdf5;
    border-radius: 50px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    margin: 0px; }
    .TeamContainer .titleContainer h3 {
      width: 100%;
      margin-top: 15px;
      text-align: center;
      font-weight: 600;
      font-size: 1.3rem;
      color: #b13b3c; }

[class*="card"] {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center; }

.OrganizerCard {
  width: 100%;
  height: 250px;
  padding: 10px;
  margin-left: 30px;
  background-color: #fffdf5 !important;
  border-radius: 0px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.2);
  transform: translateX(-15px);
  transition: .4s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-around;
  align-items: center;
  transition: all 0.5s ease-in-out; }
  .OrganizerCard:hover .cardImgCont img {
    transform: rotate(360deg); }
  .OrganizerCard.firstCorner {
    border-radius: 25px 0px 0px 0px !important; }
  .OrganizerCard.secondCorner {
    border-radius: 0px 25px 0px 0px !important; }
  .OrganizerCard.thirdCroner {
    border-radius: 0px 0px 25px 0px !important; }
  .OrganizerCard.fourthCorner {
    border-radius: 0px 0px 0px 25px !important; }
  .OrganizerCard .cardImgCont {
    width: 100%;
    height: 100px;
    border-radius: 50%;
    position: relative; }
    .OrganizerCard .cardImgCont img {
      height: 100%;
      border-radius: 100%; }
  .OrganizerCard .cardName {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 0.9rem;
    color: black; }
  .OrganizerCard .cardRole {
    max-width: 100%;
    margin: 0px;
    font-weight: 600;
    text-align: center;
    font-size: 0.9rem;
    color: #b13b3c; }
  .OrganizerCard .instagram {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
  .OrganizerCard .instagramIcon {
    width: 30px;
    height: 30px;
    margin-left: 10px; }
  .OrganizerCard .cardBio {
    width: 100%;
    margin: 0px;
    font-weight: 600;
    font-size: 0.9rem;
    color: #fa9e23; }

[class*="organizerCont"] {
  position: relative;
  margin: auto;
  width: 100%;
  height: auto; }

.Row {
  position: relative;
  margin: auto;
  width: 100%;
  height: auto; }

[class*="col"] {
  float: left;
  padding: 10px; }

@media (min-width: 1200px) {
  .colL3 {
    width: 33.33333333%;
    padding: 15px 15px; }
  .organizerContL {
    padding: 0px 300px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .colM3 {
    width: 50%;
    padding: 15px 15px; }
  .organizerContM {
    padding: 0px 250px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .colS4 {
    width: 50%;
    padding: 15px 30px; }
  .organizerContS {
    padding: 0px 145px; } }

@media (max-width: 767px) {
  .colXS6 {
    width: 50%; }
  .organizerContS {
    padding: 0px 0px; } }
