@import "../../../partials/colors";
@import "../../../partials/breakpoints.scss";

.slider-container {
    margin: 0;
    padding: 0;
    width: 100vw;
    max-width: 100vw;
    height: 92vh;
    max-height: 100vh;
    min-height: 92vh;
    margin-top: 8vh;
    // background-image: url('https://images.pexels.com/photos/1089842/pexels-photo-1089842.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    // margin-top: 10vh;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-end;
    // align-items: center;
    background-position: 0px 0px;
    .before{
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        &.active{
            animation: toLeft 1000ms;
            @keyframes toLeft {
                0% {
                    right: 0;
                    width: 100%;
                } 100% {
                    right: 100%;
                    width: 0%;
                }
            }
        }
        &.not-active{
            animation: none;
        }
    }
    .after {
        position: absolute;
        width: 0;
        height: 100%;
        left: 100%;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        &.active{
            animation: toCenter 1000ms;
            @keyframes toCenter {
                0%{
                    left: 100%;
                    width: 0%;
                } 100% {
                    left: 0;
                    width: 100%;
                }
            }
        }
        &.not-active{
            animation: none;
        }
    }
}

.logo-cover {
    width: 100%;
    animation-name: logoentry;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    opacity: 0;
    animation-delay: 0.5s;
}

@keyframes logoentry {
    0% {
        opacity: 0;
        width: 0%;
    }
    100% {
        opacity: 1;
        width: 100%;
    }
}

@include for-phone {
    .logo-cover {
        width: 80%;
    }
    @keyframes logoentry {
        0% {
            opacity: 0;
            width: 0%;
        }
        100% {
            opacity: 1;
            width: 80%;
        }
    }
}

@include for-tablet {
    .logo-cover {
        width: 70%;
    }
    @keyframes logoentry {
        0% {
            opacity: 0;
            width: 7%;
        }
        100% {
            opacity: 1;
            width: 100%;
        }
    }
}

@include for-desktop {
    .logo-cover {
        width: 50%;
        margin-top: 10vh;
    }
    @keyframes logoentry {
        0% {
            opacity: 0;
            width: 0%;
        }
        100% {
            opacity: 1;
            width: 50%;
        }
    }
}
