@import "../../../partials/colors";
@import "../../../partials/breakpoints";

.Navbar{
    width: 100%;
    overflow-x: hidden;
    z-index: 10000;
    &.right-move{
        &+ div {
            position: fixed;
            left: 70vw;
            top: 0;
            transition: 0.3s;
            overflow-x: hidden;
        }
        header{
            left: 70vw;
        }
    }
    &.left-move{
        &+ div {
            left: 0vw;
            animation: back-position 0.3s;
            @keyframes back-position {
                from {
                    position: fixed;
                    left: 70vw;
                    top: 0;
                } to {
                    position: fixed;
                    left: 0vw;
                    top: 0;
                }
            }
        }
        header{
            left: 0vw;
        }
    }
    header{
        z-index: 1000;
        transition: 0.3s;
        .mobile-version{
            display: flex;
            align-items: center;
            justify-content: space-around;
            height: 8vh;
            z-index:1000;
            box-shadow: 0px 1px 5px rgba(0,0,0,0.3);
            background-color: #fff;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            img {
                &:nth-child(2){
                    height: 10vh;
                }
                &:nth-child(1), &:nth-child(3){
                    height: 5vh;
                }
            }
        }
        .desktop-version{
            display: none;
        }
    }

    .side-bar{
        z-index: 100;
        &.side-visible{
            left: 0%;
            top: 0%;
        }
        &.side-hidde{
            left: -70vw;
            top: 0%;
        }
        transition: 0.3s;
        width: 70vw;
        height: 100vh;
        max-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        background: #fff;
        position: fixed;
        .cross{
            width: 3vh;
            margin: 20px;
        }
        ul{
            width: 100%;
            height: 80vh;
            padding: 0;
            margin: 0;
            list-style: none;
            .link{
                color: #ba484a;
                font-size: 1.5em;
                margin: 5vh 30px;
                transition: 0.3s;
                a {
                    color: #ba484a;
                }
                &:active{
                    transform: translateX(15px);
                    opacity: 0.7;
                }
            }
        }
        .logo-sidebar{
            margin: auto;
            width: 20vw;
        }
    }
    .black-div{
        position: fixed;
        left: 0;
        top: 8vh;
        height: 92vh;
        width: 0;
        transition: 0.3s;
        z-index: 100;
        &.visible-div{
            width: 100vw;
            animation: blur-animate 0.3s;
            left: 70vw;
            background-color: #0009;
            @keyframes blur-animate {
                from {
                    background-color: #0000;
                } to {
                    background-color: #0009;
                }
            }
        }
        &.hidden-div{
            left: 0;
            animation: ex-blur-animate 0.3s;
            @keyframes ex-blur-animate {
                from {
                    background-color: #0009;
                    width: 100vw;
                } to {
                    background-color: #0000;
                    width: 100vw;
                }
            }
        }
    }
}

@include for-phone {

}
@include for-tablet {

}
@include for-desktop {
    .mobile-version {
        display: none !important;
    }
    .side-bar {
        display: none !important;
    }
    .desktop-version {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: flex !important;
        align-items: center;
        justify-content: space-around;
        height: 8vh;
        z-index: 100;
        background-color: color(white, base);
        box-shadow: 0px 1px 5px color(black, base, 0.3);
        img {
            height: 15vh;
        }
        ul {
            width: 75vw;
            display: flex !important;
            align-items: center;
            justify-content: space-between;
            height: 8vh;
            margin: 0;
            padding: 0;
            list-style: none;
            font-weight: 600;
            li{
                font-size: 1em;
                a{
                    color: rgba(0, 0, 0, 0.65);
                }
            }
        }
        @keyframes side-down {
            0% {
                position: fixed;
                top: -15vh;
            } 100% {
                position: fixed;
                top: 0vh;
            }
        }
        @keyframes side-up {
            0% {
                position: fixed;
                top: 0vh;
            } 100% {
                position: fixed;
                top: -15vh;
            }
        }
    }
}
@include for-large {
}