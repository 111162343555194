@import "../../../partials/colors";
@import url('https://fonts.googleapis.com/css?family=Biryani:700|Oswald&display=swap');

.ui-about-container{
    max-width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    background-image: url('../../../images//skyline.png');
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    text-align: center;
}

.ui-about-content-text {
    width: 75vw;
    height: auto;
    background: color(yellow, base);
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vh;
    margin-bottom: 8vh;
    padding-bottom: 5vh;
    border: 1px;
    border-radius: 150px 0px 150px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    -webkit-box-shadow: -20px 20px 0px -4px rgba(255,255,255,0.26);
    -moz-box-shadow: -20px 20px 0px -4px rgba(255,255,255,0.26);
    box-shadow: -28px 28px 0px -4px rgba(255,255,255,0.26);
}
.ui-about-text{
    width: 60vw;
}

.ui-about-text-tittle{
    color: #fff;
    text-transform: uppercase;
    font-size: 4vh;
    font-weight: bold;
    display: block;
    text-align: center;
    margin: 3vh 0;
    font-family: "Biryani", sans-serif;
}
.ui-about-text-content{
    color: #fff;
    display: block;
    text-align: justify;
    font-size: 24px;
    line-height: 30px;
    font-family: "Oswald", sans-serif;
}

@media screen and (max-width: 768px){
    .ui-about-container{
        width: 100vw;
        min-width: 100vw;
    }
    .ui-img {
        display: block;
        width: 100vw;
        height: 22vh;
        background-color: black;
    }

    .ui-about-content-text {
        width: 70vw;
        height: auto;
        background: color(yellow, base);;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15vh;
        margin-bottom: 8vh;
        border: 1px;
        border-radius: 80px 0px 80px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: -20px 20px 0px -4px rgba(255,255,255,0.26);
    -moz-box-shadow: -20px 20px 0px -4px rgba(255,255,255,0.26);
    box-shadow: -20px 20px 0px -4px rgba(255,255,255,0.26);
    }
    .ui-about-text{
        width: 60vw;
    }

    .ui-about-text-tittle{
        color: #fff;
        text-transform: uppercase;
        font-size: 4vh;
        display: block;
        text-align: center;
        margin-bottom: 2vh;
    }
    .ui-about-text-content{
        color: #fff;
        display: block;
        text-align: center;
        font-size: 15px;
    }
}

@media screen and (min-width: 400px){
    .ui-about-text-content{
        font-size: 18px;
    }
}
@media screen and (max-width: 375px){
    .ui-about-text-content{
        font-size: 18px;
        line-height: 23px;
    }
}
@media screen and (max-width: 320px){
    .ui-about-text-content{
        font-size: 15px;
        line-height: 23px;
    }
}
@media screen and (min-width: 420px){
    .ui-about-text-content{
        font-size: 18px;
        line-height: 20px;
    }
}
@media screen and (min-width: 768px){
    .ui-about-text-content{
        font-size: 24px;
        line-height: 30px;
    }
}
