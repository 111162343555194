@import "../../../partials/colors";
@import "../../../partials/breakpoints";

.sponsors-container {
    width: 100vw;
    text-align: center;
    .sponsors-title {
        font-size: 2em;
        font-weight: 600;
        position: relative;
        color: color(red, base);
        animation-name: sponsorstitle;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }
    @keyframes sponsorstitle {
        0% {
            top: -1000px;
            left: -1000px;
        }
        100% {
            top: 0;
            left: 0;
        }
    }
    .sponsor-single {
        width: 40vw;
        display: inline-block;
        margin: 4vw;
        background-color: $white;
        border-radius: 15px;
        box-shadow: 5px 5px 3px rgba($color: #000, $alpha: 0.3);
        transition: background-color 0.3s ease-in-out;
        &:hover {
            .sponsor-title {
                opacity: 0;
            }
            .social-media-container {
                opacity: 1;
            }
            .sponsor-body {
                height: 10vh;
            }
        }
        .sponsor-image {
            width: 100%;
            img {
                width: 100%;
                border-radius: 15px 15px 0 0;
            }
        }
        .sponsor-body {
            height: 5vh;
            transition: height 0.5s ease-in;
            .sponsor-title {
                padding: 1vh;
                margin: 0;
                font-size: 1.2em;
                font-weight: 600;
                transition: opacity 0.5s ease-out;
            }
        }

        .social-media-container {
            width: 100%;
            display: flex;
            margin-top: -5vh;
            height: 10vh;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            opacity: 0;
            transition: opacity 0.5s ease-in;
            .sponsor-icon {
                width: 30px;
                height: 30px;
            }
        }
    }
}

@include for-phone {
    .sponsor-single {
        width: 35vw !important;
        margin: 10vw;
    }
}

@include for-tablet {
    .sponsor-single {
        width: 25vw !important;
        margin: 10vw;
    }
}

@include for-desktop {
    .sponsor-single {
        width: 20vw !important;
        margin: 15vw;
    }
}